@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.footer {
    background-color: rgba(26, 26, 26, 0.4);
    border-radius: 0; /* Optional: remove border-radius for full-width */
    color: #fff;
    padding: 40px 0; /* Keep vertical padding */
    margin: 0; /* Remove any margin */
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100vw; /* Use viewport width for full width */
    left: 0; /* Align to the left */
}

.footer-content {
    margin: 0 auto; /* Center content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px; /* Optional: Add padding for inner content spacing */
}

/* Add padding for logo and links */
.footer-logo {
    padding-left: 150px; /* Gap from the left side of the screen */
}
.footer-links li:last-child {
    margin-right: 150px; /* Add right margin to the last link */
}
.footer-links {
    
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
}
.footer-nav {
    margin: 20px 0; /* Spacing between social icons and nav links */
}

.nav-links {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust gap between links */
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-links a {
    color: white;
    text-decoration: none;
    font-size: 1em;
    font-weight: 600; /* You can adjust font weight as needed */
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #ccc; /* Change color on hover */
}


/* Media query for mobile devices */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
    }

    .footer-links {
        gap: 15px; /* Reduce gap between icons */
        margin-bottom: 15px; /* Adjust bottom margin */
        padding-right: 0; /* Remove padding on mobile */
    }

    .footer-logo img {
        width: 40px; /* Reduce logo size for mobile */
    }

    .footer-links img {
        width: 25px; /* Reduce icon size for mobile */
    }

    .footer p {
        font-size: 0.8em; /* Adjust font size for mobile */
    }
    .nav-links {
        flex-direction: column; /* Stack links vertically on mobile */
        gap: 10px;
    }
}


.footer-logo img {
    width: 50px;
    height: auto;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    list-style: none;
    /* Remove bullet points */
    padding: 0;
    /* Remove default padding */
}

.footer-links img {
    width: 35px;
    /* Adjust size of social icons */
    height: auto;
}

.footer p {
    font-size: 0.8em;
    margin: 0;
}