.custom-alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e86a31;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(-20px) translateX(-50%);
    }

    10%,
    90% {
        opacity: 1;
        transform: translateY(0) translateX(-50%);
    }

    100% {
        opacity: 0;
        transform: translateY(-20px) translateX(-50%);
    }
}