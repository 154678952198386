@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/* General styles */
.game-container {
  font-family: 'Montserrat', sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 100px auto;
  text-align: center;
}

.game-info {
  margin-bottom: 20px;
}

h2 {
  color: #333;
}

h3 {
  color: #555;
}

/* Grid for move cards */
.move-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.move-card {
  background-color: #ff8147;
  border: 2px solid #ddd;
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.move-card img {
  width: 100px;
  height: 100px;
  display: block;
  margin: 0 auto;
}

.move-card p {
  margin: 10px 0 0;
  font-weight: bold;
}

.move-card.selected {
  background-color: #ff9c67;
  border-color: transparent;
}

.submit-move-btn {
  background-color: rgba(242, 140, 40, 0.8);
  border: none;
  padding: 15px 30px;
  color: #FFF;
  font-size: 1em;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: 0;
}


/* Next round button styling */
.next-round-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff8147;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 1em;
  font-weight: bold;
}

.next-round-button:disabled {
  background-color: #ff8147;
  cursor: not-allowed;
}

.next-round-button:hover {
  background-color: #ff9c67;
}

/* Round results */
.round-results {
  display: flex; /* Use flexbox for positioning */
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between circles */
  margin-top: 20px;
  margin-bottom: 20px;
}

.result-box {
  width: 150px; 
  height: 150px; 
  background: linear-gradient(135deg, rgb(232, 106, 49), rgb(255, 248, 231));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  color: black;
}

.result-box.user,
.result-box.opponent {
  border-color: transparent;
}

/* Pseudo-element for gradient border */
.result-box::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  
  border-radius: 50%;
  z-index: -1;
}

/* Timer circle style */
.timer-circle {
  background-color: #ff8147;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  color: white;
  font-size: 2.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  box-shadow: 0 0 10px rgba(255, 129, 71, 1);
  animation: pulseTimer 1s infinite;
}

/* Timer text style */
.timer-text {
  font-size: 1em; /* Adjust size as needed */
}



/* Game over styling */
.game-over {
  text-align: center;
  margin-top: 50px;
}

.game-over button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff8147;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 1em;
  font-weight: bold;
}

.game-over button:hover {
  background-color: #ff9c67;
}

/* Move display */
.move-display {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.move-left, .move-right {
  width: 45%;
  text-align: center;
}

.move-left img {
  width: 200px;
  height: 200px;
  left: -100px;
}

.move-right img {
  width: 200px;
  height: 200px;
  right: -100px;
  padding-bottom: 10px;
}

.move-left p, .move-right p {
  color: white; /* White text color */
  font-weight: bold;
  margin-top: 10px;
}

/* CSS styles for the overlay and Lottie animation */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's on top of other elements */
}

.lottie-animation {
  width: 300px; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
}

.sound-toggle {
  position: absolute;
  top: 120px; /* Adjust this based on your navbar height; ensures 20px gap from navbar */
  right: 30px;
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.sound-toggle img {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .move-grid {
    grid-template-columns: repeat(1, 1fr); /* Stack cards in a single column */
  }

  .sound-toggle {
    top: 60px; /* Adjust for smaller screens */
    right: 10px; /* Make the position closer to the edge on smaller screens */
    width: 40px; /* Scale down for smaller screens */
    height: 40px;
  }
}

/* Move display */
.move-display {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 50px;
  justify-content: center;
  margin-top: 20px;
}

.move-card {
  background-color: rgba(255, 255, 255, 0); /* Transparent background */
  border-radius: 25px;
  padding: 15px;
  text-align: center;
  position: relative;
}

.move-card img {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.move-card p {
  color: white; /* White text color */
  font-weight: bold;
  margin-top: 10px;
}

/* Move display */
.move-display {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.move-left, .move-right {
  width: 45%;
  text-align: center;
  position: relative; /* Ensure positioning for animation */
}

.move-left {
  animation: slide-right 3s forwards; /* Apply sliding animation */
}

.move-right {
  animation: slide-left 3s forwards; /* Apply sliding animation */
}

.move-left img {
  width: 200px;
  height: 200px;
  position: absolute; /* Position absolutely for sliding effect */
  left: -200px; /* Start position off-screen to the left */
}

.move-right img {
  width: 200px;
  height: 200px;
  position: absolute; /* Position absolutely for sliding effect */
  right: -200px; /* Start position off-screen to the right */
}

.move-left p, .move-right p {
  color: white; /* White text color */
  font-weight: bold;
  margin-top: 10px;
}

@keyframes slide-right {
  from {
    transform: translateX(-100%); /* Start position off-screen to the left */
  }
  to {
    transform: translateX(0); /* End position on-screen */
  }
}

@keyframes slide-left {
  from {
    transform: translateX(100%); /* Start position off-screen to the right */
  }
  to {
    transform: translateX(0); /* End position on-screen */
  }
}
/* Move images container */
.move-images {
  display: flex;
  justify-content: center;
  gap: 50px; /* Space between images */
  margin-top: 20px;
}

.move-image {
  width: 200px; /* Width of the image container */
  height: 200px; /* Height of the image container */
  text-align: center;
  position: relative;
  overflow: hidden; /* Ensures the image doesn’t overflow the container */
}

.move-image img {
  width: 100%; /* Make the image fit the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 25px; /* Rounded corners if desired */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Optional shadow for better visibility */
  animation: slide-in 1s forwards; /* Apply sliding animation */
}

/* Animation for user move from left with rotation */
.move-image.user img {
  animation: slide-in-left 1s forwards; /* Apply sliding animation from left */
  transform: rotate(45deg); /* Rotate image 45 degrees */
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%) rotate(45deg); /* Start position off-screen to the left with rotation */
    opacity: 0; /* Start invisible */
  }
  to {
    transform: translateX(0) rotate(45deg); /* End position on-screen with rotation */
    opacity: 1; /* End visible */
  }
}

/* Animation for opponent move from right with rotation */
.move-image.opponent img {
  animation: slide-in-right 1s forwards; /* Apply sliding animation from right */
  transform: rotate(315deg); /* Rotate image 135 degrees */
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%) rotate(315deg); /* Start position off-screen to the right with rotation */
    opacity: 0; /* Start invisible */
  }
  to {
    transform: translateX(0) rotate(315deg); /* End position on-screen with rotation */
    opacity: 1; /* End visible */
  }
}
/* Game Over Container Styling */
.game-over {
  position: fixed; /* Overlay on top of other content */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the container */
  background: rgb(255, 255, 255,0.03); /* Stylish background gradient linear-gradient(135deg, #1a1a1a, #333); */
  
  color: #fff8e7;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1000; /* Ensure it's on top */
  overflow: hidden; /* For background effects */
}

/* Flashing Game Over Text */
.game-over h2 {
  font-size: 4em;
  margin-bottom: 20px;
  letter-spacing: 5px;
  text-transform: uppercase; /* Make text uppercase */
  animation: flash 1.5s infinite; /* Flashing animation */
  color: #E86a31; /* Orange shade for Game Over text */
  text-shadow: 0 0 10px #E86a31, 0 0 20px #E86a31, 0 0 30px #E86a31; /* Glowing effect */
}

/* Winning or Losing Text */
.game-over p {
  font-size: 1.8em;
  margin-bottom: 40px;
  animation: fadeIn 3s forwards;
  text-shadow: 0 0 10px #ff8147; /* Subtle glow effect */
}

/* Button Styling */
.game-over button {
  padding: 15px 40px;
  font-size: 1.5em;
  background: rgb(232, 106, 49,0.7); /* Gold color */
  color: #333;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 5px 15px rgba(255, 204, 0, 0.4);
  animation: pulse 2s infinite; /* Pulsing button */
}

.game-over button:hover {
  background: rgb(232, 106, 49,0.4); /* Darker gold */
  transform: scale(1.1); /* Scale up on hover */
  box-shadow: 0 8px 25px rgba(232, 106, 49, 0.6);
}

/* Flash Animation */
@keyframes flash {
  0%, 100% {
    color: #fff8e7; /* White color */
  }
  50% {
    color: #E86a31; /* Orange shade */
  }
}

/* Fade-In Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Pulsing Button Animation */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgb(232, 106, 49,0.4);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 25px rgb(232, 106, 49,0.6);
  }
}

/* Background Particles Effect */
.game-over::before, .game-over::after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(232, 106, 49, 0.25); /* Orange shade for circles */
  border-radius: 50%;
  animation: moveParticles 10s infinite;
  z-index: 0;
}

.game-over::before {
  top: -50px;
  left: -50px;
  animation-duration: 12s;
  animation-direction: alternate;
}

.game-over::after {
  bottom: -50px;
  right: -50px;
  animation-duration: 15s;
  animation-direction: alternate-reverse;
}

/* Particle Animation */
@keyframes moveParticles {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.5) translate(100px, 100px);
  }
}
.round-number {
  font-size: 2.5em; /* Adjust the size as needed */
  font-weight: bold;
  background: linear-gradient(to right, #E97451, #F5E0C3); /* Deep carrot orange to creme beige gradient */
  background-clip: text;
  -webkit-background-clip: text; /* For WebKit-based browsers */
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 20px 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Optional: add a slight shadow for emphasis */
}
/* Add this to your existing CSS */
.game-over button:not(:last-child) {
  margin-right: 40px; /* Adds a 10px gap between the buttons */
}
/* Game Over Button Styling */
.game-over button {
  padding: 15px 40px;
  font-size: 1.5em;
  background: #ff8147; /* Solid color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 20px; /* Border radius */
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 5px 15px rgba(255, 204, 0, 0.4);
  animation: pulse 2s infinite; /* Pulsing button */
  margin-right: 10px; /* Add 10px gap between buttons */
}

.game-over button:hover {
  background: #e76f4e; /* Darker shade on hover */
  transform: scale(1.1); /* Scale up on hover */
  box-shadow: 0 8px 25px rgba(232, 106, 49, 0.6);
}
