/* Base styles for navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    /* Space between logo and wallet section */
    align-items: center;
    padding: 10px 20px;
    /* Adjusted for better spacing */
    background-color: rgba(26, 26, 26, 0.6);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s;
    /* Smooth transition */
}

.navbar.connected {
    background-color: rgba(26, 26, 26, 0.8);
    /* Slightly darker when connected */
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    /* Adjusting for space between elements */
    align-items: center;
    width: 100%;
}

.navbar .logo {
    position: relative;
    /* Changed from absolute to relative */
}

.navbar .logo img {
    max-width: 50px;
    height: auto;
}

.navbar .nav-links {
    z-index: 1100;
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.navbar .nav-links li a {
    color: #FFF;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 600;
    transition: color 0.3s ease;
}

.navbar .nav-links li a:hover {
    color: #e86a31;
}

.navbar .wallet-section {
    display: flex;
    align-items: center;
    /* Align items vertically */
    gap: 10px;
    /* Space between wallet button and address */
}

.navbar .btn-connect-wallet,
.wallet-address {
    background-color: rgb(255, 129, 71);
    border: none;
    padding: 10px 15px;
    /* Adjust padding for better fit */
    color: #FFF;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1.1em;
    display: flex;
    /* Use flexbox for centering text */
    align-items: center;
    /* Center text vertically */
    justify-content: center;
    /* Center text horizontally */
    min-width: 150px;
    /* Set a minimum width to maintain consistent size */
    overflow: hidden;
    /* Hide overflow text */
    text-overflow: ellipsis;
    /* Show ellipsis for overflowing text */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.navbar .btn-connect-wallet:hover {
    background-color: #ff9c67;
}

/* Responsive styles */
@media (max-width: 768px) {
    .navbar {
        padding: 10px;
        /* Less padding on smaller screens */
    }

    .navbar .nav-links {
        display: none;
        /* Hide navigation links on mobile */
        flex-direction: column;
        /* Stack links vertically */
        position: absolute;
        /* Position absolutely */
        top: 60px;
        /* Position below navbar */
        left: 0;
        width: 100%;
        background-color: rgba(26, 26, 26, 0.9);
        /* Dark background for links */
        transition: max-height 0.4s ease-in-out;
        max-height: 0;
        /* Hidden by default */
        overflow: hidden;
        /* Prevent content from showing when hidden */
    }

    .navbar .nav-links.open {
        display: flex;
        /* Show links when open */
        max-height: 300px;
        /* Adjust height based on menu content */
    }

    .navbar .nav-links li {
        margin: 10px 0;
        /* Space between list items */
    }

    .navbar .nav-links li a {
        font-size: 1.2em;
        /* Larger font size on mobile */
        padding: 10px;
        display: block;
        /* Block display for easy tapping */
        text-align: center;
        width: 100%;
    }

    /* Hamburger icon for small screens */
    .navbar .hamburger {
        display: block;
        cursor: pointer;
        position: absolute;
        left: 20px;
        top: 10px;
        z-index: 1100;
    }

    .navbar .hamburger span {
        display: block;
        width: 30px;
        height: 3px;
        background-color: #FFF;
        margin: 6px 0;
        transition: 0.4s;
    }

    /* Change button size for smaller screens */
    .navbar .wallet-section {
        flex-direction: row;
        /* Align buttons horizontally */
        gap: 5px;
        /* Adjust spacing */
    }

    /* Open hamburger animation */
    .hamburger.open span:nth-child(1) {
        transform: translateY(9px) rotate(45deg);
    }

    .hamburger.open span:nth-child(2) {
        opacity: 0;
    }

    .hamburger.open span:nth-child(3) {
        transform: translateY(-9px) rotate(-45deg);
    }
}

@media (min-width: 769px) {
    .navbar .hamburger {
        display: none;
        /* Hide hamburger on larger screens */
    }
}