/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

/* Header Styles */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px auto 20px; /* Adjust margin as needed */
  padding: 0 20px; /* Adjust for spacing */
  width: 90%;
  max-width: 1100px;
  position: relative; /* Allows for absolute positioning of children */
}

/* Active Lobbies Title Styles */
.active-lobbies-title {
  font-size: 36px; /* Adjust font size as needed */
  font-weight: bold;
  color: #ff8147; /* Adjust color if needed */
  margin-left: 20px; /* Adjust as needed for left positioning */
  margin-right: 400px; /* Adjust gap between title and button */
}

/* Create Lobby Button Styles */
.create-lobby-button {
  background-color: #ff8147; /* Main button color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  /* Manually adjustable properties */
  margin-right: 0px; /* Adjust as needed for right positioning */
}

.create-lobby-button:hover {
  background-color: #ff9c67; /* Hover color */
}

.view-button {
  margin-top: 20px;
  padding: 12px;
  color: #fff;
  /* White text for contrast */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 300px;
  background-color: #ff8147;
  /* Set the background color to orange */
}

.view-button:disabled {
  background-color: #ccc;
  /* Gray when disabled */
  cursor: not-allowed;
}

.view-button:hover:not(:disabled) {
  background-color: #ff9c67;
  /* Lighter orange on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Spacer to adjust gap between header and cards */
.header-spacer {
  height: 20px; /* Adjust the height to control the gap */
}

/* Lobby Cards Container */
.lobby-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  justify-content: center;
  margin: 20px auto;
  width: 90%;
  max-width: 800px;
  
  /* Adjust padding to make space for the header and button */
  padding-top: 20px; /* Adjust based on the height of .header-spacer */
}

/* Lobby Card Styles */
.lobby-card {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent black */
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
  position: relative;
  margin-bottom: 20px; /* Space between cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures content is spaced evenly */
  height: 280px; /* Adjust height for vertical look */
}

.lobby-card:hover {
  transform: translateY(-5px);
}

/* Profile Picture (PFP) */
.lobby-card img.pfp {
  width: 80px; /* Adjust as needed */
  height: 80px; /* Adjust as needed */
  border-radius: 50%;
  margin-bottom: 10px;
  position: absolute;
  top: 10px; /* Adjust positioning as needed */
  left: 50%;
  transform: translateX(-50%);
}

/* Card Content */
.lobby-card-content {
  margin-top: 100px; /* Adjust to make room for the profile picture */
}

/* Mini Copy Button Styles */
.mini-copy-button {
  position: absolute;
  top: 10px; /* Adjust to position at the top */
  right: 10px; /* Adjust to position at the right */
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mini-copy-button img {
  width: 20px; /* Adjust size to your preference */
  height: 20px;
  opacity: 0.75; /* Slight transparency */
  transition: opacity 0.3s ease;
}

.mini-copy-button img:hover {
  opacity: 1; /* Full opacity on hover */
}
