
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body.play-page {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(to top right, rgba(17,17,51,0.9), rgba(12,12,55,0.9), rgba(191, 85, 42, 0.85));
    color: #FFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 130px;
}


/* General styling for game cards */
.game-cards {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Each card styling */
.card {
    
    border-radius: 8px;
    overflow: hidden;
    width: 30%;
    text-align: center;
    transition: background-color 0.3s, transform 0.3s;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); /* Subtle shadow */
}

.card:hover {
    background-color: #0804045f; /* Light background on hover */
    transform: translateY(-5px); /* Slight lift effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* More prominent shadow on hover */
}


/* Image styling for game image */
.game-image {
    width: 150px; /* Default width */
    height: 250px; /* Maintain aspect ratio */
    margin: 10px auto; /* Center the image vertically and horizontally */
    display: block; /* Ensures the image is centered within the card */
    border-radius: 15px; /* Rounded corners for the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for the image */
}

/* Manually adjustable image styling */
.game-image-adjustable {
    /* You can adjust these values as needed */
    width: 100px; /* Change to desired width */
    height: 300px; /* Change to desired height */
    max-width: 100%; /* Ensures image doesn't overflow its container */
    margin-top: 10px; /* Adjust top margin */
    margin-bottom: 10px; /* Adjust bottom margin */
}

/* Card text styling */
.card-text {
    padding: 10px 15px;
}

/* Buttons styling */
.btn-play {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #F28C28;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: not-allowed; /* Default to not allowed */
    opacity: 0.6;
    transition: opacity 0.3s;
}

/* Manually adjustable button styling */
.btn-play-adjustable {
    /* You can adjust these values as needed */
    padding: 15px 30px; /* Change button size */
    font-size: 1.2em; /* Change font size */
    margin-top: 15px; /* Adjust top margin */
}

/* Active play button */
.btn-play[onclick] {
    cursor: pointer;
    opacity: 1;
}

.btn-play:hover {
    opacity: 1;
}

/* Optional: For responsive behavior */
@media (max-width: 768px) {
    .game-cards {
        flex-direction: column;
        align-items: center;
    }
    .card {
        width: 90%;
    }
}
