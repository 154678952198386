/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
    background: linear-gradient(to top right, rgba(17, 17, 51, 0.9), rgba(12, 12, 55, 0.9), rgba(191, 85, 42, 0.85));
    min-height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

/* Content Styling */
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-radius: 10px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

/* Card Layout */
.rules-columns {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.rules-card {
    flex: 1;
    min-width: 300px;
    max-width: 350px;
    /* Ensures that the cards do not expand too much */
    background: rgba(255, 255, 255, 0.15);
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s, background 0.3s, font-size 0.3s ease-in-out;
    text-align: center;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
    /* Ensure uniform height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rules-card:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 0.2);
}

/* Enlarge on click */
.rules-card.expanded {
    background: rgba(255, 255, 255, 0.25);
    padding: 30px;
    font-size: 1.2em;
}

/* Responsive font size for titles */
.rules-card h2 {
    font-family: 'Montserrat', sans-serif;
    color: #f0a500;
    /* Custom yellowish color */
    font-weight: 600;
    margin-bottom: 10px;
    font-size: clamp(1.2rem, 2.5vw, 1.5rem);
    /* Dynamically adjusts the font size */
    transition: font-size 0.3s ease-in-out;
}

.rules-card.expanded h2 {
    font-size: clamp(1.5rem, 3vw, 2.2rem);
    /* Enlarged on click, still responsive */
}

/* Styling for the list of rules */
.rules-list {
    font-size: 0.9em;
    line-height: 1.5;
    text-align: justify;
    color: #fff;
    margin: 10px 0;
    padding-left: 20px;
    flex-grow: 1;
    /* Ensures the list takes up available space */
}

.rules-list li {
    margin-bottom: 10px;
}

/* Image animation */
.rules-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: transform 0.3s ease, width 0.3s ease;
}

.rules-card.expanded .rules-image {
    transform: scale(1.1);
    /* Slight enlargement */
    width: 95%;
    /* Limit excessive growth */
}

/* Existing styles remain the same */

/* Add styles for subheadings */
.rules-card h3 {
    font-family: 'Montserrat', sans-serif;
    color: #f0a500;
    /* Same custom yellowish color */
    font-weight: 600;
    margin: 15px 0;
    font-size: clamp(1rem, 2vw, 1.2rem);
    /* Responsive font size */
    transition: font-size 0.3s ease-in-out;
}

/* Ensure the subheadings are also responsive and match the styling */
.rules-card.expanded h3 {
    font-size: clamp(1.2rem, 2.5vw, 1.5rem);
    /* Slightly larger when expanded */
}