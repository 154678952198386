
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
body {
    background: linear-gradient(to top right, rgba(17,17,51,0.9), rgba(12,12,55,0.9), rgba(191, 85, 42, 0.85));
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}


.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-radius: 10px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 100px; /* Increased margin to lower the card */
}


.faq-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
}


.faq-container {
    width: 100%;
}


.faq-item {
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}


.faq-question {
    padding: 15px;
    cursor: pointer;
    background: transparent;
    font-size: 1.2em;
    font-weight: 600;
    color: #fff;
    transition: background 0.3s ease, color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.faq-question:hover {
    background: rgba(255, 255, 255, 0.1);
}


.faq-answer {
    max-height: 0;
    overflow: hidden;
    padding: 0 15px;
    transition: max-height 0.3s ease, padding 0.3s ease;
    background: transparent;
    color: #fff;
    font-size: 1em;
    line-height: 1.5em;
}


.faq-item.active .faq-answer {
    max-height: 200px;
    padding: 15px;
}

.faq-question::after {
    content: '\25BC'; 
    font-size: 1.2em;
    color: #fff;
    transition: transform 0.3s ease;
    margin-left: 10px; 
}


.faq-item.active .faq-question::after {
    transform: rotate(180deg);
}


.navbar {
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 20px 20px;
    background-color: rgba(26, 26, 26, 0.6);
    position: fixed; 
    width: 100%;
    top: 0;
    z-index: 1000; 
    height: 50px; 
}

.navbar .container {
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 100%;
    position: relative;
}

.navbar .logo {
    position: absolute; 
    left: 20px; 
}

.navbar .logo img {
    max-width: 50px; 
    height: auto;
}

.navbar .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    justify-content: center; 
    margin: 0;
    padding: 0;
}

.navbar .nav-links li a {
    color: #FFF;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 600;
    transition: color 0.3s ease;
}

.navbar .nav-links li a:hover {
    color: #e86a31;
}

.navbar .wallet-button {
    position: absolute; 
    right: 20px; 
    top: -10px; 
}

.navbar .btn-connect-wallet {
    background-color: rgba(242, 140, 40, 0.8);
    border: none;
    padding: 10px 20px;
    color: #FFF;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.1em;
    transition: background-color 0.3s;
}

.navbar .btn-connect-wallet:hover {
    background-color: #F28C28;
}
