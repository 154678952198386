
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');


body {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(to top right, rgba(17,17,51,0.9), rgba(12,12,55,0.9), rgba(191, 85, 42, 0.85));
    color: #FFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.navbar {
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 20px 40px;
    background-color: rgba(26, 26, 26, 0.9);
    position: fixed; 
    width: 100%;
    top: 0;
    z-index: 1000; 
}

.navbar .container {
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 100%;
}

.navbar .logo {
    position: absolute; 
    left: 50px; 
}

.navbar .logo img {
    max-width: 50px; 
    height: auto;
}

.navbar .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.navbar .nav-links li a {
    color: #FFF;
    text-decoration: none;
    font-size: 1.1em;
}

.navbar .wallet-button {
    position: absolute; 
    right: 50px;
    top: 20px; 
}

.navbar .btn-connect-wallet {
    background-color: rgba(242, 140, 40, 0.8);
    border: none;
    padding: 10px 20px;
    color: #FFF;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1.1em;
    transition: background-color 0.3s;
}

.navbar .btn-connect-wallet:hover {
    background-color: #F28C28;
}


.hero {
    text-align: left; 
    padding: 20px 20px; 
    margin: 0 auto; 
    max-width: 1000px; 
    position: relative;
}

.hero h1 {
    font-size: 3em;
    margin-bottom: 20px; 
    color: #F28C28;
    margin-left: 0; 
}

.hero p {
    font-size: 2.2em;
    margin-bottom: 20px; 
    color: rgba(255, 255, 255, 0.8);
    margin-left: 0; 
}

.hero .btn-play-now {
    background-color: rgba(242, 140, 40, 0.8);
    border: none;
    padding: 15px 30px;
    color: #FFF;
    font-size: 1em;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s, transform 0.3s;
    margin-left: 0; 
}

.hero .btn-play-now:hover {
    background-color: #F28C28;
    transform: scale(1.1);
}


@media (max-width: 768px) {
    .hero {
        padding: 50px 10px; 
    }

    .hero h1 {
        font-size: 2.5em;
    }

    .hero p {
        font-size: 1.8em;
    }

    .hero .btn-play-now {
        font-size: 0.9em;
        padding: 10px 20px;
    }
}


.wits-arena {
    text-align: center;
    padding: 50px 20px;
}

.wits-arena h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #F28C28;
}

.wits-arena .game-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.wits-arena .card {
    background-color: rgba(51, 51, 51, 0.8);
    padding: 20px;
    border-radius: 20px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.wits-arena .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.wits-arena .card p {
    margin: 20px 0;
    color: #FFF;
}

.wits-arena .card .btn-play {
    background-color: rgba(242, 140, 40, 0.8);
    border: none;
    padding: 10px 20px;
    color: #FFF;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s, transform 0.3s;
}

.wits-arena .card .btn-play:hover {
    background-color: #F28C28;
    transform: scale(1.1);
}


.slider {
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: 370px;
    overflow: hidden;
    display: flex;
    justify-content: center; 
    align-items: center;
}

.item {
    position: absolute;
    width: 300px; 
    height: auto; 
    text-align: justify;
    background-color: rgba(13, 13, 13, 0.8);
    border-radius: 20px;
    padding: 20px;
    transition: 0.5s;
    left: calc(50% - 150px); 
    top: 0;
    font-family: 'Montserrat', sans-serif;
}

#next {
    position: absolute;
    right: 50px;
    top: 40%;
}

#prev {
    position: absolute;
    left: 50px;
    top: 40%;
}

#prev, #next {
    color: #fff;
    background: none;
    border: none;
    font-size: xxx-large;
    font-family: monospace;
    font-weight: bold;
    opacity: 0.5;
    transition: opacity 0.5s;
}

#prev:hover,
#next:hover {
    opacity: 1;
}


body.play-page {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(135deg, #0D0D0D 30%, #1A1A1A 100%);
    color: #FFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px; 
}


.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1200px; 
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    margin: 0 auto;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px; 
}

.navbar .logo {
    flex: 1; 
}

.navbar .logo img {
    max-width: 50px; 
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    flex: 2; 
    justify-content: center; 
}

.nav-links li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 600; 
    transition: color 0.3s;
}

.nav-links li a:hover {
    color: #f28c28;
}

.wallet-button {
    flex: 1; 
    display: flex;
    justify-content: flex-end; 
}

.btn-connect-wallet {
    background-color: #f28c28;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1.1em;
    transition: background-color 0.3s;
}

.btn-connect-wallet:hover {
    background-color: #d47424; 
}


.hero {
    text-align: left;
    padding: 100px 20px;
    max-width: 800px; 
}

.hero h1 {
    font-size: 3em;
    margin-bottom: 20px;
    color: #f28c28;
}

.hero p {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.btn-play-now {
    background-color: #f28c28;
    border: none;
    padding: 15px 30px;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s, transform 0.3s;
}

.btn-play-now:hover {
    background-color: #d47424; 
    transform: scale(1.1);
}




.slider {
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 370px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item {
    position: absolute;
    width: 80%;
    height: 100%;
    background-color: rgba(13, 13, 13, 0.8); 
    border-radius: 20px;
    padding: 20px;
    transition: 0.5s;
    left: 50%;
    transform: translateX(-50%);
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
}

#next {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

#prev {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

#prev, #next {
    color: #fff;
    background: none;
    border: none;
    font-size: xxx-large;
    font-family: monospace;
    font-weight: bold;
    opacity: 0.5;
    transition: opacity 0.5s;
}

#prev:hover,
#next:hover {
    opacity: 1;
}


@media (max-width: 768px) {
    .hero {
        padding: 50px 10px;
    }

    .hero h1 {
        font-size: 2.5em;
    }

    .hero p {
        font-size: 1.2em;
    }

    .btn-play-now {
        font-size: 1em;
        padding: 10px 20px;
    }

    .game-cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 100%;
        max-width: 300px;
    }
}

/* styles.css */
.token-benefits h2 {
    text-align: center; /* Center the heading */
    color: #f28c28; /* Apply the specified color */
    font-size: 32px; /* Adjust font size as needed */
    font-weight: bold; /* Make heading bold */
    margin-bottom: 20px; /* Space below the heading */
}
/* General styling adjustments for uniformity */
.token-benefits {
    text-align: center;
    margin-top: 40px;
    padding: 20px;  /* Add padding for spacing */
}

.token-benefits h2 {
    font-size: 2.5em;  /* Increase font size for heading */
    margin-bottom: 30px; /* Increase margin for spacing */
}

.benefit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0; /* Increase margin for spacing between benefits */
    padding: 20px; /* Add padding to make the benefit area larger */
    font-size: 1.2em; /* Increase font size for benefit text */
}

.benefit-image {
    width: 150px; /* Increase image size */
    height: auto;
    margin-right: 30px; /* Increase margin for better spacing */
}

.benefit-text {
    max-width: 600px; /* Increase max-width for text block */
    text-align: left;
}

.benefit-reverse {
    flex-direction: row-reverse; /* Reverse the order for alternating layout */
}

.benefit-reverse .benefit-image {
    margin-right: 0; /* Reset right margin */
    margin-left: 30px; /* Increase left margin for better spacing */
}
/* styles.css */

/* styles.css */

/* Tokenomics Section Styling */
.tokenomics-section {
    margin-top: 40px; /* Space above the section */
    padding: 20px; /* Padding for better spacing */
    text-align: center; /* Center the heading above the card */
}

.tokenomics-heading {
    margin-bottom: 20px; /* Space below the heading */
    font-size: 52px; /* Increased font size */
    font-weight: bold; /* Make heading bold */
    color: #f28c28; /* Adjust text color if needed */
}

.tokenomics-card {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center content horizontally */
    max-width: 850px; /* Max width of the card */
    margin: 0 auto; /* Center the card horizontally */
    padding: 20px; /* Padding inside the card */
    border: 1px solid #6e686888; /* Light border for card */
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Shadow for card */
    background-color: rgba(0, 0, 0, 0.1); /* White background */
}

.tokenomics-image {
    width: 40%; /* Adjust this size as needed */
    margin-right: 20px; /* Space between image and text */
}

.tokenomics-text {
    flex: 1; /* Take up remaining space */
    text-align: left; /* Align text to the left */
}

.tokenomics-text ul {
    list-style-type: none; /* Remove default list style */
    padding: 0;
}

.tokenomics-text li {
    margin-bottom: 10px; /* Space between pointers */
    font-size: 16px; /* Adjust text size as needed */
}

/* styles.css */

/* About Us Section Styling */
.about-us {
    margin-top: 40px; /* Space above the section */
    padding: 20px; /* Padding for better spacing */
    text-align: center; /* Center the heading and cards */
}

.about-us h2 {
    margin-bottom: 20px; /* Space below the heading */
    font-size: 32px; /* Adjust font size as needed */
    font-weight: bold; /* Make heading bold */
    color: #f28c28; /* Heading color */
}

.about-us-card {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center content horizontally */
    max-width: 850px; /* Max width of the card */
    margin: 20px auto; /* Center the card horizontally */
    padding: 20px; /* Padding inside the card */
   
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Shadow for card */
    background-color: rgba(255, 255, 255, 0.08); /* White background */
}

.about-us-card img {
    width: 25%; /* Adjust this size as needed */
    margin-right: 20px; /* Space between image and text */
}

.about-us-card .card-text {
    flex: 1; /* Take up remaining space */
    text-align: left; /* Align text to the left */
}
.about-us-card h3 {
    color: #F28C28;
}


.about-us-card img.about-image1 {
    width: 100%; /* Default width, adjust as needed */
    height: auto; /* Maintain aspect ratio */
    max-width: 120px; /* Maximum width for the image, adjust as needed */
}
.hero h1 {
    margin-top: 100px; /* Adjust the value as needed */
    margin-bottom: 10px; /* Optional: Adjust bottom margin as needed */
}
