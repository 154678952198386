/* Betting page container */
.betting-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 100px auto;
  text-align: center;
}

.betting-page h1 {
  color: white;
  margin-bottom: 20px;
}

/* Inputs section styling */
.betting-inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.betting-inputs label {
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

.betting-inputs input,
.betting-inputs select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

/* High-bet button with animated border */
.high-bet {
  background-color: darkorange;
  color: red;
  font-weight: bold;
  border: 2px solid transparent;
  /* Initially transparent border */
  padding: 12px 24px;
  font-size: 1.2em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(255, 0, 0, 0.3);
  animation: borderMove 3s linear infinite;
  transition: background-color 0.3s ease;
}

.high-bet:hover {
  background-color: darkorange;
  color: white;
  box-shadow: 0 4px 8px rgba(255, 0, 0, 0.5);
}

.high-bet.selected {
  animation: none;
  /* Stop the animation when selected */
  border: 2px solid white;
  /* Solid white border after selection */
}

/* Keyframes for the moving border */
@keyframes borderMove {
  0% {
    border-color: transparent;
    box-shadow: inset 0 0 0 2px white;
  }

  25% {
    box-shadow: inset 0 0 0 2px transparent, inset 4px 0 0 0 white;
  }

  50% {
    box-shadow: inset 0 0 0 2px transparent, inset 0 4px 0 0 white;
  }

  75% {
    box-shadow: inset 0 0 0 2px transparent, inset -4px 0 0 0 white;
  }

  100% {
    box-shadow: inset 0 0 0 2px transparent, inset 0 -4px 0 0 white;
  }
}

/* Create lobby button styles */
.create-lobby-button {
  margin-top: 20px;
  padding: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 300px;
  background-color: #ff8147;
}

.create-lobby-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.create-lobby-button:hover:not(:disabled) {
  background-color: #ff9c67;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Buttons for quick bets */
.quick-bets button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #ff8147;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 1em;
  font-weight: bold;
  margin: 5px;
}

.quick-bets button:hover {
  background-color: #ff9c67;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.matic-buttons {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  margin-top: 20px;
}

.wvt-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Currency selection carousel */
.currency-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.currency-image {
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currency-image img {
  width: 100%;
  height: auto;
}

.carousel-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.carousel-button.left {
  left: -120px;
}

.carousel-button.right {
  right: -120px;
}

.carousel-button img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.carousel-button:hover img {
  opacity: 0.8;
}

/* Choices container for stone, paper, scissors */
.choices-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.choice-step {
  margin: 10px 0;
}

.choice-step fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.choice-step legend {
  font-weight: bold;
  color: white;
}

.choice-box {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  background-color: #ff8147;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  padding: 10px;
  position: relative;
}

.choice-box img {
  width: 120px;
  height: 120px;
  display: block;
}

.choice-box:hover {
  background-color: #ff9c67;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.choice-box:active {
  transform: scale(0.95);
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

/* 3D Effect for Choice Boxes */
.shadow-3d {
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.shadow-3d:hover {
  transform: rotateX(10deg) rotateY(10deg);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}