.claim-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Centers the content vertically */
  min-height: 100vh;
  /* Full viewport height */
  padding: 20px;
}

.claim-button {
  background-color: #ff8147;
  /* Default button color */
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  /* Smooth transition on hover */
  margin-top: auto;
  /* Push the button to the bottom of the card */
}

.claim-button:hover {
  background-color: #ff9c67;
  /* Darker green on hover */
}

.claim-button:disabled {
  background-color: #9e9e9e;
  /* Gray color for disabled state */
  cursor: not-allowed;
  /* Change cursor to indicate disabled */
  opacity: 0.7;
  /* Slightly transparent */
}

.card-style {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 20px;
  padding: 20px;
  width: 65%;
  /* Adjustable width */
  max-width: 500px;
  /* Adjustable max width */
  height: auto;
  /* Make height dynamic */
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  /* Centers text inside the card */
  display: flex;
  /* Enable flexbox for alignment */
  flex-direction: column;
  /* Stack children vertically */
  align-items: center;
  /* Center children horizontally */
}

.currency-container {
  margin-bottom: 10px;
  /* Space between image and content */
  width: 100%;
  /* Make the container full width of the card */
  display: flex;
  /* Enable flexbox for centering */
  justify-content: center;
  /* Center image horizontally */
}

.currency-image {
  width: 67.5px;
  /* Adjust size as needed */
  height: 67.5px;
  /* Maintain aspect ratio */
}

.unclaimed-wins-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* Align content vertically */
}

.navigation-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* Space between content and navigation buttons */
}

.nav-img {
  width: 15px;
  /* Adjustable size of arrow */
  height: auto;
  cursor: pointer;
  /* Indicates clickable */
}

.nav-btn {
  background: #ff8147;
  /* Orange background for the button */
  border-radius: 20px;
  /* Makes the button circular */
  width: 35px;
  /* Circle size */
  height: 35px;
  /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f28c28;
  /* Orange border around the circle */
  cursor: pointer;
  /* Cursor change on hover */
}

.prev-btn {
  position: absolute;
  left: -70px;
  /* Adjustable position */
  top: 50%;
  transform: translateY(-50%);
}

.next-btn {
  position: absolute;
  right: -70px;
  /* Adjustable position */
  top: 50%;
  transform: translateY(-50%);
}